define("discourse/plugins/discourse-solved/discourse/templates/connectors/user-activity-bottom/solved-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#link-to "userActivity.solved"}}
    {{d-icon "check-square"}} {{i18n "solved.title"}}
  {{/link-to}}
  
  */
  {
    "id": "Xo9sfmi0",
    "block": "[[[6,[39,0],[\"userActivity.solved\"],null,[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],[\"check-square\"],null]],[1,\" \"],[1,[28,[35,2],[\"solved.title\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-solved/discourse/templates/connectors/user-activity-bottom/solved-list.hbs",
    "isStrictMode": false
  });
});