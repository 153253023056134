define("discourse/plugins/discourse-solved/discourse/connectors/bread-crumbs-right/solved-status-filter", ["exports", "I18n", "discourse/lib/url", "discourse-common/lib/get-owner"], function (_exports, _I18n, _url, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      const router = (0, _getOwner.getOwner)(this).lookup("router:main");
      if (!component.siteSettings.show_filter_by_solved_status || router.currentPath === "discovery.categories") {
        return false;
      } else if (component.siteSettings.allow_solved_on_all_topics) {
        return true;
      } else {
        const controller = (0, _getOwner.getOwner)(this).lookup("controller:navigation/category");
        return controller && controller.get("category.enable_accepted_answers");
      }
    },
    setupComponent(args, component) {
      const statuses = ["all", "solved", "unsolved"].map(status => {
        return {
          name: _I18n.default.t(`solved.topic_status_filter.${status}`),
          value: status
        };
      });
      component.set("statuses", statuses);
      const queryStrings = window.location.search;
      if (queryStrings.match(/solved=yes/)) {
        component.set("status", "solved");
      } else if (queryStrings.match(/solved=no/)) {
        component.set("status", "unsolved");
      } else {
        component.set("status", "all");
      }
    },
    actions: {
      changeStatus(newStatus) {
        let location = window.location;
        let queryStrings = location.search;
        let params = queryStrings.startsWith("?") ? queryStrings.substr(1).split("&") : [];
        params = params.filter(param => !param.startsWith("solved="));
        if (newStatus && newStatus !== "all") {
          newStatus = newStatus === "solved" ? "yes" : "no";
          params.push(`solved=${newStatus}`);
        }
        queryStrings = params.length > 0 ? `?${params.join("&")}` : "";
        _url.default.routeTo(`${location.pathname}${queryStrings}${location.hash}`);
      }
    }
  };
});